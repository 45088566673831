//*******************************************************************************
// TIMELINE
//*******************************************************************************

.timelinewrapper {
	position: relative;
}



//
// Vertical Red Line
//
.timeline__verticalline {
	display: block;
	position: absolute;
	top: -40px;
	left: 50%;
	left: calc(50% - 2px);
	height: 0;
	width: 3px;
	background-color: color(red500);
	z-index: -1;

	@include breakpoint(mid) {
		top: 0;
	}

	@include breakpoint(max) {
		width: 4px;
	}
}



//
// One Timeline item
//
.timeline {
	position: relative;
	display: flex;
	margin-top: 80px;
	margin-bottom: 80px;
	min-height: 0;
	opacity: 0;

	@include breakpoint(mid) {
		margin-top: 0;
		margin-bottom: 40px;
	}

	& ~ .timeline {
		margin-top: 0;
	}

	&.timeline--odd {
		@include breakpoint(mid) {}
	}

	&.timeline--even {
		@include breakpoint(mid) {
			justify-content: flex-end;
		}
	}
}



//
// Timmeline Inner Wrapper
//
.timeline__inner {
	width: 100%;
	height: fit-content;
	border-radius: $border-radius;
	background-color: color(coolgray100);
	transition: all .2s $transition-function;

	@include breakpoint(mid) {
		width: 45%;
		width: calc(50% - 5vw);
	}

	@include breakpoint(max) {
		.timeline--odd & {
			margin-left: -1vw;
			width: calc(45% - 4vw);
		}
		.timeline--even & {
			margin-right: -1vw;
			width: calc(45% - 4vw);
		}
	}

	&:hover,
	&:focus,
	&.is--open {
		box-shadow: $box-shadow--1;
		background-color: color(white);
	}
}



//
// Title h3 Element
//
.timeline__title {
	@include font-family(sansregular);
	@include bodytextsize;
	margin-bottom: 0;

	@include breakpoint(mid) {
		margin-bottom: 0;
	}

	.timeline.is--open & {
		@include font-family(headblack);
	}
}




//
// Timeline Button
//
.timeline__button {
	display: flex;
	align-items: center;
	width: 100%;
	border: none;
	text-align: left;
	padding: 20px;
	background-color: transparent;
	transition: $transition;

	@include breakpoint(mid) {
		background-position: right 2vw center;
		padding: 1.5vw;
	}

	@include breakpoint(max) {
		line-height: 1.5vw;
		padding: 0.75vw 1vw;
	}

	&.focus-visible {
		color: color(red500);
		border-radius: $border-radius;
		box-shadow: $box-shadow--1;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		color: color(red500);
		cursor: pointer;
	}

	.timeline__title.is--open & {
		color: color(red500);
	}
}



//
// Timeline ID @ button
//
.timeline__id {
	@include font-family(headblack);
	flex: 0 0 32px;
	font-size: 9vw;
	line-height: 10vw;
	margin-right: 5vw;
	text-align: center;

	@include breakpoint(min) {
		flex: 0 1 6vw;
		font-size: 36px;
		line-height: 40px;
		margin-right: 20px;
	}

	@include breakpoint(mid) {
		flex: 0 1 auto;
		min-width: 48px;
		font-size: calc(36px + (64 - 36) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(40px + (72 - 40) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-right: 1.5vw ;
	}

	@include breakpoint(max) {
		font-size: 4vw;
		line-height: 4.5vw;
		margin-right: 1vw;
	}


	.timeline__title.is--open &,
	.timeline__button:focus &,
	.timeline__button:hover & {
		svg,
		svg path,
		svg .st0 {
			fill: color(red500);
		}
	}
}



//
// Text @ button
//
.timeline__buttontext {
	@include font-family(headblack);
	font-size: 4vw;
	line-height: 6vw;
	flex: 1 1 auto;

	@include breakpoint(min) {
		font-size: 16px;
		line-height: 24px;
	}

	@include breakpoint(mid) {
		font-size: calc(16px + (20 - 16) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(24px + (32 - 24) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
	}

	.no-msie & {
		display: flex;
		align-items: center;
		flex-grow: 1;
		min-height: 48px;
		// min-height: 15vw;
		// min-height: clamp(60px, 15vw, 60px);
	}
}



//
// Toggle Icon @ button
//
.timeline__toggleicon {
	// outline: 1px solid blue;
	flex: 0 0 auto;
	display: block;
	width: 20px;
	height: 20px;
	margin-left: 5vw;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#282D37" fill-rule="nonzero"/></svg>');

	.timeline__button:focus &,
	.timeline__button:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 11c.562 0 1 .406 1 1 0 .25-.094.5-.281.719l-8 8a1.101 1.101 0 01-.719.28c-.25 0-.5-.093-.719-.28l-8-8a1.101 1.101 0 01-.28-.719c0-.25.093-.5.28-.719a1.1 1.1 0 01.72-.28c.25 0 .5.093.718.28L16 18.594l7.281-7.313C23.5 11.094 23.75 11 24 11z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}

	.timeline__button[aria-expanded=true] & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}

	.timeline__button[aria-expanded=true]:focus &,
	.timeline__button[aria-expanded=true]:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M24 21c.562 0 1-.407 1-1 0-.25-.094-.5-.281-.719l-8-8A1.101 1.101 0 0016 11c-.25 0-.5.094-.719.281l-8 8a1.101 1.101 0 00-.28.719c0 .25.093.5.28.718a1.1 1.1 0 00.72.282c.25 0 .5-.094.718-.282L16 13.406l7.281 7.312c.219.188.469.282.719.282z" fill="#ec0016" fill-rule="nonzero"/></svg>');
	}

	@include breakpoint(mid) {
		margin-left: 1vw;
	}

	@include breakpoint(max) {
		width: 32px;
		height: 32px;
		margin-left: 2vw;
	}
}



//
// Collapse
//
.timeline__collapse {
	overflow: hidden;
	margin-top: 0;
	padding: 0 20px 3vw 20px;
	height: 0;
	transition: all $transition-time $transition-function;

	.timeline__inner:not(.is--open) & {
		display: none;
	}

	@include breakpoint(mid) {
		padding: 0 1.5vw;
	}

	@include breakpoint(max) {
		padding: 0 1vw;
	}

	p:last-child {
		margin-bottom: 0;
	}

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 3vw;

		@include breakpoint(mid) {
			height: 30px;
		}

		@include breakpoint(max) {
			height: 12px;
		}
	}
}



//
// Timeline Media
//
.timeline__media {

	@include breakpoint(mid) {
		position: absolute;
		top: 0;
		right: 0;
		width: 45%;
		width: calc(50% - 5vw);

		.timeline--even & {
			right: auto;
			left: 0;
		}
	}

	@include breakpoint(max) {
		.timeline--odd & {
			width: calc(45% - 4vw);
			margin-right: -1vw;
		}
		.timeline--even & {
			width: calc(45% - 4vw);
			margin-left: -1vw;
		}
	}

	.image {
		margin-bottom: 8px;

		@include breakpoint(mid) {
			opacity: 0;
			transition: opacity .1s $transition-function;
		}
	}

	.image__desc {
		padding-left: 0;
		padding-right: 0;
	}

}


//
// Time Active item
//
.timeline.timeline--active {
	background-color: color(white);
}



//
// Red Line horizontal (branch) @ Timeline item
//
.timeline__branch {
	@include breakpoint(mid) {
		display: block;
		position: absolute;
		top: 50%;
		right: auto;
		bottom: auto;
		left: 0;
		height: 3px;
		width: 30%;
		margin-left: 0;
		background-color: color(red500);
		z-index: -1;
	}

	@include breakpoint(max) {
		height: 4px;
	}

	.timeline--even & {
		@include breakpoint(mid) {
			left: auto;
			right: 0;
		}
	}
}



//
// Timeline Branch Marker Circles
//
.timeline__branch::after {
	position: absolute;
	content: '';
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 3px solid color(red500);
	background-color: color(white);
	top: -40px;
	z-index: 1;

	.timeline--odd & {
		left: 50%;
		transform: translate(-50%, -50%);

		@include breakpoint(mid) {
			left: auto;
			right: 0;
			top: 50%;
			transform: translate(50%, -50%);
		}
	}

	.timeline--even & {
		left: 50%;
		transform: translate(-50%, -50%);

		@include breakpoint(mid) {
			left: 0;
			top: 50%;
		}
	}


	.timeline--finished & {
		display: block;
		width: 32px;
		height: 32px;
		background-color: color(white);
		background-size: 60%;
		background-repeat: no-repeat;
		background-position: center center;
		@include background-svg('<svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M.333 7.44c0-.435.374-.75.773-.75.3 0 .374.05.548.219l4.088 3.943L15.987.884a.726.726 0 01.549-.217c.448 0 .797.387.797.75 0 .193-.074.363-.249.532l-10.793 10.5a.786.786 0 01-.549.218.726.726 0 01-.548-.218L.558 7.973a.74.74 0 01-.225-.532z" fill="#EC0016" fill-rule="nonzero"/></svg>');
	}


	.is--openOnPageload & {
		width: 32px;
		height: 32px;
		background-color: color(red500);
		background-size: 75%;
		background-repeat: no-repeat;
		background-position: center center;
		@include background-svg('<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12.971 1.671l-.104 1.507 1.61.92 1.256-.816a.66.66 0 01.356-.125c1.015 0 2.971 3.653 2.971 4.645a.68.68 0 01-.377.606l-1.339.67v1.841l1.34.67a.68.68 0 01.376.607c0 1.018-2.076 4.645-2.971 4.645a.66.66 0 01-.356-.126l-1.255-.816-1.611.92.104 1.486c.061.87-1.604 1.026-2.971 1.026-1.291 0-3.034-.134-2.971-1.026l.104-1.485-1.61-.92-1.256.815a.66.66 0 01-.356.126C2.968 16.84.94 13.2.94 12.175c0-.23.15-.473.377-.586l1.339-.67V9.08l-1.34-.67a.68.68 0 01-.376-.607c0-.99 2.01-4.645 2.971-4.645a.66.66 0 01.356.125l1.255.816 1.611-.92L7.05 1.67C7.004.841 8.499.677 9.864.667H10c1.293 0 3.031.14 2.971 1.004zM10 1.985c-.523 0-1.067.063-1.59.167l.083 1.36a.663.663 0 01-.334.607L5.836 5.46a.633.633 0 01-.335.083.623.623 0 01-.355-.105l-1.13-.753a8.029 8.029 0 00-1.59 2.783l1.192.607a.663.663 0 01.377.586v2.678a.66.66 0 01-.377.586l-1.213.606a8.131 8.131 0 001.61 2.762l1.13-.732a.663.663 0 01.356-.125.66.66 0 01.335.104l2.323 1.318c.212.12.35.377.334.628l-.083 1.36a8.213 8.213 0 001.59.168c.523 0 1.067-.063 1.59-.168l-.083-1.36a.693.693 0 01.334-.628l2.323-1.318a.66.66 0 01.335-.104c.125 0 .242.053.355.125l1.151.732a8.436 8.436 0 001.59-2.762l-1.213-.606a.66.66 0 01-.377-.586V8.66a.66.66 0 01.377-.586l1.213-.607a8.43 8.43 0 00-1.61-2.783l-1.13.753a.623.623 0 01-.356.105.633.633 0 01-.335-.084L11.84 4.12a.663.663 0 01-.334-.607l.083-1.36A8.213 8.213 0 0010 1.985zm0 4.682A3.328 3.328 0 0113.333 10 3.328 3.328 0 0110 13.333 3.328 3.328 0 016.667 10 3.328 3.328 0 0110 6.667zM10 8a2 2 0 10.001 4.001A2 2 0 0010 8z" fill="#FFF" fill-rule="nonzero"/></svg>');
	}
}



//
// Timeline Milestone Item
//
.timeline__milestone {
	opacity: 0;
	display: flex;
	justify-content: center;
	padding: 20px;
	margin-bottom: 20px;

	@include breakpoint(mid) {
		padding: 0 1.5vw;
		margin-bottom: 40px;
	}

	@include breakpoint(max) {
		padding: 0 1vw;
	}
}

.timeline__milestone-inner {
	background-color: color(red500);
	color: color(white);
	text-align: center;
	padding: 6px 12px;
	border-radius: $border-radius;
}

.timeline__milestone-headline {
	line-height: 1.2;
	margin-bottom: 0;
}

.timeline__milestone-content {
	p {
		line-height: 1.2;
		margin-bottom: 0;
	}
}

.timeline + .timeline__milestone {
	margin-top: -60px;

	@include breakpoint(mid) {
		margin-top: 0;
	}
}

.timeline__milestone + .timeline .timeline__branch:after {
	display: none;

	@include breakpoint(mid) {
		display: block;
	}
}


