.textmediatext {
	position: relative;
	overflow: hidden;
}



.textmediatext__backgroundimage {
	max-width: none;
	width: 120%;
	height: 130%;
	object-fit: cover;
	margin: -10% -20%;

	@include breakpoint(mid) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 0;
		max-width: none;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}



.textmediatext__row {
	display: flex;
	align-items: flex-start;
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint($min: 800px) {
		margin: 0 calc(5vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(max) {
		margin-top: 6vw;
		margin-bottom: 6vw;
	}
}

.textmediatext__row:first-child {
	justify-content: flex-end;
	background-color: color(coolgray100);
	border-radius: $border-radius;
	padding: 5vw;

	@include breakpoint(mid) {
		margin-top: 6vw;
		padding: 6vw 2vw;
	}
}

.textmediatext__row:last-child {
	padding: 5vw;

	@include breakpoint(mid) {
		padding: 6vw 2vw;
	}
}




.textmediatext__content {
	// outline: 1px solid red;
	position: relative;
	z-index: 1;

	@include breakpoint(mid) {
		width: 37.5vw;
	}

	@include breakpoint(max) {
		width: 28vw;
	}
}



//
// 2nd Type - First Text on the Left; second right
//
.textmediatext--reversed {

	.textmediatext__row:first-child {
		justify-content: flex-start;

		@include breakpoint(max) {
			padding: 6vw 0 6vw 2vw;
		}
	}

	.textmediatext__row:last-child {
		justify-content: flex-end;
	}
}
