.filters {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.filters__item {
	flex: 0 0 auto;
	margin: 0.5em;
	padding: 0;
}

.filters__input {
	display: none;
}

.filters__label {
	background-color: color(coolgray200);
	border-radius: $border-radius;
	display: inline-block;
	outline: none;
	padding: 7px 8px;
	transition: background-color $transition-time $transition-function, color $transition-time $transition-function;

	@include breakpoint(mid) {
		padding: 8px 16px;
	}

	&:hover,
	.filters__input:checked + & {
		background-color: color(coolgray600);
		color: color(white);
		cursor: pointer;
	}
}
