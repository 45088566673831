//*******************************************************************************
// IMAGE
//*******************************************************************************


.image {
	position: relative;
}


.image__wrapper {
	position: relative;
}


.image__item {
	max-width: none;
	width: 100%;
	border-radius: $border-radius;
}







//
// Image Description
//
.image__desc {
	color: color(coolgray500);
	margin-top: 2vw;
	margin-bottom: 0;
	padding: 0 5vw;
	font-size: 2.5vw;
	line-height: 3vw;

	@include breakpoint(min) {
		font-size: 10px;
		line-height: 12px;
	}

	@include breakpoint(mid) {
		font-size: calc(10px + (14 - 10) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(12px + (20 - 12) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-top: 1vw;
		margin-bottom: 1vw;
	}

	@include breakpoint(max) {
		font-size: .875vw;
		line-height: 1.25vw;
	}
}



//
// Image Meta Data like Copyright
//
.image__meta {
	display: block;
}
