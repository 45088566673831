//*******************************************************************************
// FOOTER
//*******************************************************************************

.footer {
	margin-top: 10vw;
}

.footer__wrapper {
	display: grid;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: repeat(20, 5vw);
	padding: 2vw 0;
	background: color(coolgray600);
	color: color(bright);

	.footer--toprow + & {
		margin-top: 0;
	}
}


.footer__navigation--left,
.footer__navigation--center,
.footer__navigation--right {
	@include font-family(regular);
	display: flex;
	align-items: flex-start;
	align-self: center;
	justify-content: center;

	li {
		display: inline-block;
		padding: 0;
		margin: 0;

		@include breakpoint(mid) {
			&:not(:last-child) {
				margin-right: 2vw;
			}
		}

		.list__link {
			display: block;
			min-width: 85px;
			padding: 5px;
			text-align: center;
			color: color(bright);

			@include breakpoint(mid) {
				min-width: auto;
			}

			&::after {
				// outline: 1px solid #ccc;
				content: '';
				display: inline-block;
				width: 24px;
				height: 12px;
				background-size: 6px;
				background-position: left 8px top 2px;
				background-repeat: no-repeat;
				background-color: transparent;
				@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#fff" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
				transition: all $transition-time $transition-function;

				@include breakpoint(mid) {
					height: 14px;
					background-size: 7px;
				}

				@include breakpoint(max) {
					height: 15px;
					background-size: 8px;
				}
			}

			&.btn--hover,
			&:focus,
			&:hover {

				&::after {
					background-position: left 12px top 2px;
				}
			}
		}
	}
}



// Footer - Links
.footer__navigation--left,
.footer__navigation--right {
	grid-row: 1;
	grid-column: 2/20;
	flex-wrap: wrap;
	margin-bottom: 10px;
	padding-left: 0;

	@include breakpoint(mid) {
		flex-wrap: nowrap;
		justify-content: center;
	}

	@include breakpoint(lg) {
		grid-column: 2/12;
		justify-content: flex-start;
		margin-bottom: 0;
	}
}



// Footer - Social List
// .footer__navigation--right {
// 	grid-row: 2;
// 	grid-column: 2/20;
// 	margin-bottom: 10px;
// 	padding-left: 0;

// 	@include breakpoint(mid) {
// 		justify-content: center;
// 		margin-bottom: 0;
// 	}

// 	@include breakpoint(lg) {
// 		grid-row: 1;
// 		grid-column: 11/20;
// 		justify-content: flex-end;
// 	}
// }



//
// Footer - Top Row (other Projects)
//
.footer--toprow {
	background: color(coolgray100);
	color: color(dark);
	display: block;
	padding-left: 5vw;
	padding-right: 5vw;

	.list--unstyled {
		margin-bottom: 0;
		padding-left: 0;

		@include breakpoint(mid) {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}

	.list__item {
		text-align: center;

		@include breakpoint(mid) {
			text-align: left;
			margin-right: 20px;
		}

		&:first-child {
			display: block;
			padding: 5px 15px;
			color: color(coolgray500);

			@include breakpoint(mid) {
				padding: 5px 5px;
			}
		}
	}

	.list__link {
		color: color(coolgray500);
		display: block;
		padding: 5px 15px;

		&:hover {
			color: color(red600);
		}
	}
}


// To-Top Link
.list__link--totop.list__link {
	// outline: 1px solid red;
	display: block;
	width: 50px;
	min-width: auto !important;
	max-width: 40px;
	height: 100%;
	padding: 5px;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center center;

	@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M16 26c.562 0 1-.437 1-1V9.406l6.281 6.313A.961.961 0 0024 16c.562 0 1-.406 1-1 0-.25-.063-.5-.281-.719l-8-8a.962.962 0 00-.72-.28c-.25 0-.5.062-.718.28l-8 8a.963.963 0 00-.281.72c0 .593.469 1 1 1 .25 0 .5-.063.719-.282L15 9.406V25c0 .563.437 1 1 1z" fill="#fff" fill-rule="evenodd"/></svg>');

	@include breakpoint(mid) {
		background-size: 60%;
	}

	@include breakpoint(max) {
		background-size: 1.6vw;
	}
}
