//*******************************************************************************
// Download List
//*******************************************************************************


.downloadlist {

	.section--small & {
		margin-left: -5vw;
		margin-right: -5vw;

		@include breakpoint(mid) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.downloadlist__item:last-child .downloadlist__link {
		border-bottom: 1px solid transparent;
	}
}


.downloadlist__title {
	padding-left: 5vw;
	padding-right: 5vw;

	@include breakpoint(mid) {
		padding-left: 0;
		padding-right: 0;
	}
}



.downloadlist__list {
	@include breakpoint(mid) {
		margin-left: -1.5vw;
		margin-right: -1.5vw;
	}

	@include breakpoint(max) {
		margin-left: -1vw;
		margin-right: -1vw;
	}
}



.downloadlist__item {
	position: relative;
	list-style: none;
	margin-bottom: 0;

	@include breakpoint($min: 800px) {
		padding: 0;
	}
}


.downloadlist__link {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 8px 5vw;
	color: color(dark);
	border-bottom: 1px solid color(coolgray200);
	transition: $transition;

	.downloadlist:hover & {
		border-bottom: 1px solid transparent;
	}

	&:focus,
	&:hover {
		color: color(cyan600);
		text-decoration: none;
		border-radius: $border-radius;
		box-shadow: $box-shadow--1;
		border-bottom: 1px solid transparent;
	}

	&:active {
		color: color(cyan700);
	}

	@include breakpoint($min: 800px) {
		flex-wrap: nowrap;
		padding: 0;
	}
}

.downloadlist__link-name {
	$textfontsizemin:   14;
	$textlineheightmin: 20;
	$textfontsizemax:   18;
	$textlineheightmax: 24;

	@include breakpoint(min) {
		font-size: $textfontsizemin + px;
		line-height: $textlineheightmin + px;
	}

	display: inline-block;
	flex: 1 0 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 40px;
	margin-bottom: 4px;

	@include breakpoint(mid) {
		font-size: calc(#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: 1.5;
		flex: 1 1 auto;
		margin: 1.4vw 1.5vw 1.3vw 1.5vw;
		padding: 0;
	}

	@include breakpoint(max) {
		margin: 1vw;
	}
}


.downloadlist__link-filetype,
.downloadlist__link-filesize,
.downloadlist__link-date,
.downloadlist__link-icon {
	font-size: calc(#{$textsmallfontsizemin}px + (#{$textsmallfontsizemax} - #{$textsmallfontsizemin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));
	line-height: calc(#{$textsmalllineheightmin}px + (#{$textsmalllineheightmax} - #{$textsmalllineheightmin}) * ((100vw - #{$textminbreakpoint}px) / (1600 - #{$textminbreakpoint})));

	display: inline-block;
	margin-bottom: 0;
	color: color(coolgray500);
	flex: 0 0 auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	.downloadlist__link:focus &,
	.downloadlist__link:hover & {
		color: color(cyan600);
	}

	.downloadlist__link:active & {
		color: color(cyan700);
	}

	@include breakpoint(min) {
		margin-bottom: 0;
	}

	@include breakpoint(mid) {
		$textfontsizemin:   14;
		$textlineheightmin: 20;
		$textfontsizemax:   18;
		$textlineheightmax: 24;

		font-size: calc(#{$textfontsizemin}px + (#{$textfontsizemax} - #{$textfontsizemin}) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: 1;

		margin: 1.4vw 1.5vw 1.3vw 1.5vw;
		color: color(dark);
	}

	@include breakpoint(max) {
		margin: 1vw;
	}
}



//
// Filetype
//
.downloadlist__link-filetype {
	.section & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 8%;
			max-width: 100px;
		}
	}

	.section--small & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 10%;
			max-width: 140px;
		}
	}
}



//
// Filesize
//
.downloadlist__link-filesize {
	.section & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 10%;
			max-width: 80px;
		}
	}

	.section--small & {
		flex-basis: 15vw;

		@include breakpoint(mid) {
			flex-basis: 13%;
			max-width: 130px;
		}

		@include breakpoint(max) {
			max-width: 90px;
		}
	}
}



//
// Date
//
.downloadlist__link-date {
	.section & {
		flex-basis: 20vw;

		@include breakpoint(mid) {
			flex-basis: 16.7%;
			max-width: 100px;
		}
	}

	.section--small & {
		flex-basis: 20vw;

		@include breakpoint(mid) {
			flex-basis: 16.7%;
			max-width: 160px;
		}

		@include breakpoint(max) {
			max-width: 110px;
		}
	}
}

.downloadlist__link-icon {
	position: absolute;
	top: 50%;
	right: 5vw;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	transform: translate(0, -50%);
	background: right top no-repeat transparent;
	background-size: 100%;
	@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M4 21c0-.563.438-1 1-1 .563 0 1 .437 1 1v6c0 .562.438 1 1 1h18c.562 0 1-.438 1-1v-6c0-.563.437-1 1-1 .562 0 1 .437 1 1v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6zm5.281-5.313a.97.97 0 01-.28-.687c0-.5.343-1 1-1 .25 0 .5.094.718.281L15 18.593V3c0-.563.438-1 1-1 .563 0 1 .438 1 1v15.593l4.281-4.312A1.1 1.1 0 0122 14c.594 0 1 .437 1 1 0 .25-.094.5-.281.719l-6 6a1.1 1.1 0 01-.719.28.97.97 0 01-.688-.28l-6.03-6.032z" fill="#282D37" fill-rule="nonzero"/></svg>');

	.downloadlist__link:focus &,
	.downloadlist__link:hover & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M4 21c0-.563.438-1 1-1 .563 0 1 .437 1 1v6c0 .562.438 1 1 1h18c.562 0 1-.438 1-1v-6c0-.563.437-1 1-1 .562 0 1 .437 1 1v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6zm5.281-5.313a.97.97 0 01-.28-.687c0-.5.343-1 1-1 .25 0 .5.094.718.281L15 18.593V3c0-.563.438-1 1-1 .563 0 1 .438 1 1v15.593l4.281-4.312A1.1 1.1 0 0122 14c.594 0 1 .437 1 1 0 .25-.094.5-.281.719l-6 6a1.1 1.1 0 01-.719.28.97.97 0 01-.688-.28l-6.03-6.032z" fill="#0087B9" fill-rule="nonzero"/></svg>');
	}

	.downloadlist__link:active & {
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M4 21c0-.563.438-1 1-1 .563 0 1 .437 1 1v6c0 .562.438 1 1 1h18c.562 0 1-.438 1-1v-6c0-.563.437-1 1-1 .562 0 1 .437 1 1v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6zm5.281-5.313a.97.97 0 01-.28-.687c0-.5.343-1 1-1 .25 0 .5.094.718.281L15 18.593V3c0-.563.438-1 1-1 .563 0 1 .438 1 1v15.593l4.281-4.312A1.1 1.1 0 0122 14c.594 0 1 .437 1 1 0 .25-.094.5-.281.719l-6 6a1.1 1.1 0 01-.719.28.97.97 0 01-.688-.28l-6.03-6.032z" fill="#006A96" fill-rule="nonzero"/></svg>');
	}

	@include breakpoint($min: 800px) {
		position: relative;
		top: auto;
		right: auto;
		margin: 1.5vw;
		// flex-basis: 5.5%;
		// min-width: 40px;
		// max-width: 80px;
		background-position: center center;
		transform: none;
	}

	@include breakpoint($min: 1600px) {
		// flex-basis: 59px;
		flex-basis: 32px;
		width: 32px;
		height: 32px;
		margin: 0.75vw 1vw 0.75vw 0.75vw;
	}
}


.downloadlist:focus-within .downloadlist__link {
	border-bottom: 1px solid transparent;
}



//
// Inside Section Small
//
.section--small {
	.downloadlist__link-filetype,
	.downloadlist__link-filesize,
	.downloadlist__link-date,
	.downloadlist__link-icon {
		margin-left: 0;
	}

}
