
@include breakpoint(max) {


	.section.boxedlayout {
		// outline: 2px solid magenta;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
	}

	.section--small.boxedlayout {
		// outline: 2px solid magenta;
		max-width: 800px;
	}

	.section--full.boxedlayout {
		// outline: 2px solid magenta;
		max-width: 1600px;
	}

	.section--has-background.boxedlayout {
		// outline: 2px solid magenta;
		padding: 80px 240px;
	}




	// Teaser with Image
	.boxedlayout {
		.teaser--image {
			max-width: 1440px;
			margin-left: auto;
			margin-right: auto;
		}
		.teaser__inner {
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;
		}
		.teaser__title {
			font-size: 64px;
			line-height: 72px;
			padding: 64px;
		}
		.teaser__content p {
			font-size: 2 * 16 + 0px;
			line-height: 2.5 * 16 + 0px;
		}
	}



	// blogpost column
	.boxedlayout .blogpost {
		.blogpost__title {
			margin-bottom: 2 * 16 + 0px;
		}
		.blogpost__content {
			padding-bottom: 4 * 16 + 0px;
		}
		.blogpost__taglist {
			max-width: 20 * 16 + 0px;
		}
	}



	.boxedlayout {
		body,
		p,
		li {
			font-size: 1.125 * 16 + 0px;
			line-height: 2 * 16 + 0px;
		}

		p,
		ul {
			margin-bottom: 2 * 16 + 0px;
		}

		.h1, h1,
		.title__main {
			font-size: 3 * 16 + 0px;
			line-height: 3.5 * 16 + 0px;
		}

		.h2, h2,
		.title__subline {
			font-size: 2 * 16 + 0px;
			line-height: 2.5 * 16 + 0px;
		}

		.h3, h3 {
			font-size: 1.25 * 16 + 0px;
			line-height: 2 * 16 + 0px;
			margin-bottom: 2 * 16 + 0px;
		}


		.title {
			padding-bottom: 4 * 16 + 0px;
		}

		.title__subline {
			margin-top: 2 * 16 + 0px;
		}

		.textcontent {

		}

		.image__desc {
			margin-top: 1 * 16 + 0px;
		}
	}













} // breakpoint(max)


