//*******************************************************************************
// COLOR HELPERS
//*******************************************************************************


//
// For each key in the map, created an own class
//
@each $name, $value in $colors {
	.text-color--#{$name} { color: $value; }
	.background-color--#{$name} { background-color: $value; }
}
