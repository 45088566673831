//*******************************************************************************
// Scheduleboard
//*******************************************************************************

// .scheduleboardwrapper {}

.scheduleboard {
	@include breakpoint(mid) {
		margin-bottom: 3.75vw;
	}
}

.scheduleboard__title {
	margin-bottom: 2vw;

	@include breakpoint(mid) {
		margin-bottom: 1vw;
	}

	@include breakpoint(max) {
		margin-bottom: 0.75vw;
	}
}



// .scheduleboard__list {}


.scheduleboard__item {
	display: flex;
	padding-top: 2vw;
	padding-bottom: 2vw;
	border-top: 1px solid color(coolgray200);

	@include breakpoint(mid) {
		padding-top: 1vw;
		padding-bottom: 1vw;
	}

	@include breakpoint(max) {
		padding-top: 0.75vw;
		padding-bottom: 0.75vw;
	}
}



.scheduleboard__time {
	display: block;
	flex-shrink: 0;
	width: 80px;


	@include breakpoint(mid) {
		width: 15vw;
		padding-right: $spacer * 1;
	}
}



.scheduleboard__content {
	padding-left: $spacer * 3;

	@include breakpoint(mid) {
		padding-left: 0;
	}
}



