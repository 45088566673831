//*******************************************************************************
// Title Component
//
//*******************************************************************************


.title {
	// outline: 1px solid black;
	margin-bottom: 0;
	padding-bottom: 12vw;

	@include breakpoint(min) {
		margin-bottom: 0;
		padding-bottom: 48px;
	}
	@include breakpoint(mid) {
		margin-bottom: 0;
		padding-bottom: 4vw;
	}
}



.title__main {
	// outline: 1px solid red;
	@include headline1;
	display: block;
	margin-bottom: 0 !important;
}



.title__subline {
	// outline: 1px solid blue;
	@include headline2;
	display: block;
	margin-bottom: 0 !important;
	margin-top: 6vw;

	@include breakpoint(min) {
		margin-top: 24px;
	}

	@include breakpoint(mid) {
		margin-top: 2vw;
	}
}
