//*******************************************************************************
// Blogpost - milestone
//*******************************************************************************


.blogpost--milestone {
	margin-left: 0;
	margin-right: 0;

	.blogpost__milestone-divider {
		position: relative;
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}

	.blogpost__milestone-line {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
		height: 6px;
		background: color(coolgray200);

		//white box
		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 10vw;
			transform: translateX(-50%);
			background: color(white);
		}
	}

	.blogpost__milestone-icon {
		position: relative;
		display: block;
		width: 60px;
		height: 60px;

	}

	.blogpost__date {
		text-align: center;
		margin-bottom: 0;
	}

	.blogpost__content {
		text-align: center;
	}

}

