//*******************************************************************************
// COOKIE PLACEHOLDER
//*******************************************************************************


.cookieplaceholder {

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
	object-fit: contain;
	background-color: rgba(240, 243, 245, .9);
	z-index: 100;

	&.is--hidden {
		display: none;
	}
}


.cookieplaceholder__inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
	text-align: center;
	margin: 0 auto;
}



//
// Teaser with Video-Player
//
.teaser__videowrapper .cookieplaceholder {
	.cookieplaceholder__inner {
		width: 94%;
	}

	.cookieplaceholder__title {
		font-size: 20px;
		line-height: 1;

		@include breakpoint(min) {
			margin-bottom: 1vw;
		}
	}

	.cookieplaceholder__text {
		font-size: 100%;
		line-height: 1.3;
		margin-bottom: 1.5vw;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.btn {
		padding: 4px 16px 4px;
		height: 30px;
	}
}
