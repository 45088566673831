//*******************************************************************************
// MENU
//*******************************************************************************

//
// Hamburger Menü Icon
//
.showmenu__label {
	position: absolute;
	right: 5vw;
	display: block;
	width: 24px;
	height: 20px;
	margin-top: 10px;
	margin-bottom: 0;
	z-index: 200;

	@include breakpoint(mid) {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	.header__navigation--bigger & {
		@include breakpoint(mid) {
			display: block;
		}

		@include breakpoint(lg) {
			display: none;
		}
	}
}

.showmenu__icon {
	&,
	&::before,
	&::after {
		display: block;
		position: absolute;
		width: 24px;
		height: 3px;
		content: '';
		cursor: pointer;
		background-color: color(coolgray300);
	}

	top: 0px;
	background: #000;

	&::before {
 		top: 8px;
	}

	&::after {
		top: 16px;
		width: 16px;
	}
}

.showmenu__input {
	display: none;

	&:checked + .menu {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		margin: 0;
		padding: 20vh 5vw;
		background-color: color(bright);
		z-index: 100;
	}
}



//
// Main Menu
//
.menu {
	display: none;
	font-size: 19px;
	line-height: 1.7;
	margin: 0;
	padding: 0;
	z-index: 100;

	@include breakpoint(mid) {
		display: flex;
		align-items: center;
		font-size: inherit;
		line-height: inherit;
	}

	.header__navigation--bigger & {
		@include breakpoint(mid) {
			display: none;
			font-size: 19px;
			line-height: 1.7;
		}

		@include breakpoint(lg) {
			display: flex;
			align-items: center;
			font-size: inherit;
			line-height: inherit;
		}
	}
}

.menu__item,
.submenu__item {
	font-size: inherit;
	line-height: inherit;
}

.menu__item {
	list-style-type: none;
	position: relative;
	display: block;
	margin-bottom: 0;

	@include breakpoint(mid) {
		flex-shrink: 0;

		&:not(:last-child) {
			margin: 0 3vw 0 0;
		}
	}

	.header__navigation--bigger & {
		@include breakpoint(mid) {
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;
		}

		@include breakpoint(lg) {
			&:not(:last-child) {
				margin: 0 2.5vw 0 0;
			}
		}

		@include breakpoint(max) {
			&:not(:last-child) {
				margin: 0 3vw 0 0;
			}
		}
	}
}

.menu__link {
	@include font-family(sansbold);
	display: block;
	color: color(black);
	padding: 8px 16px;

	&:hover {
		text-decoration: none;
		color: color(red500);
	}

	&:active {
		color: color(red600);
	}
}


.menu__link.btn {
	margin-top: 2vw;
	margin-left: 16px;

	.header__navigation--bigger & {
		@include breakpoint(lg) {
			margin-top: 0;
		}
	}
}



//
// Menu Item with Submenu & Arrow Icon
//
.menu__item--has-submenu {
	> .menu__link {
		@include breakpoint(mid) {
			padding-right: 44px;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 8px;
			right: 10px;
			width: 24px;
			height: 24px;
			background-position: center;
			background-repeat: no-repeat;
			background-color: transparent;
			@include background-svg('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 8c.563 0 1 .438 1 1 0 .25-.125.5-.313.688l-5.968 6.03A1.044 1.044 0 0112 16c-.25 0-.531-.094-.719-.281L5.312 9.688C5.125 9.5 5 9.25 5 9c0-.563.438-1 1-1 .281 0 .5.094.719.281L12 13.562l5.281-5.28A.99.99 0 0118 8z" fill="#282D37" fill-rule="evenodd"/></svg>');

			@include breakpoint(mid) {
				top: 50%;
				transform: translate(0, -50%);
			}
		}

		&:focus::after,
		&:hover::after {
			@include background-svg('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 8c.563 0 1 .438 1 1 0 .25-.125.5-.313.688l-5.968 6.03A1.044 1.044 0 0112 16c-.25 0-.531-.094-.719-.281L5.312 9.688C5.125 9.5 5 9.25 5 9c0-.563.438-1 1-1 .281 0 .5.094.719.281L12 13.562l5.281-5.28A.99.99 0 0118 8z" fill="#ec0016" fill-rule="evenodd"/></svg>');
		}

		.header__navigation--bigger & {
			padding-right: 16px;

			@include breakpoint(mid) {
				&::after {
					top: 22px;
				}
			}

			@include breakpoint(lg) {
				padding-right: 44px;

				&::after {
					top: 50%;
				}
			}
		}
	}
}



//
// Sub-Menu List
//
.submenu {
	margin-top: 0;
	margin-left: 0;
	margin-bottom: 0;
	padding: 0;

	@include breakpoint(mid) {
		display: block;
		position: absolute;
		top: 50px;
		left: auto;
		right: -3px;
		width: 100%;
		min-width: 260px;
		max-width: 500px;
		margin-top: -5px;
		padding: 16px;
		background-color: color(bright);
		box-shadow: $box-shadow--1;
		border-radius: $border-radius;
		z-index: 200;

		&::before {
			content: '';
			display: block;
			width: 41px;
			height: 20px;
			position: absolute;
			top: -14px;
			right: 5px;
			@include background-svg('<svg width="41" height="20" xmlns="http://www.w3.org/2000/svg"><defs><filter x="-.5%" y="-1.3%" width="101.1%" height="103%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.51372549 0 0 0 0 0.57254902 0 0 0 0 0.607843137 0 0 0 1 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><path d="M850.5 0L867 12h-33z" filter="url(#a)" transform="translate(-830 2)" fill="#FFF" fill-rule="evenodd"/></svg>');
		}
	}

	@include breakpoint(max) {
		top: 3vw;
		min-width: 16vw;
	}

	.header__navigation--bigger & {
		@include breakpoint(mid) {
			position: relative;
			top: auto;
			right: auto;
			max-width: none;
			box-shadow: none;
			padding: 8px;

			&::before {
				display: none;
			}
		}

		@include breakpoint(lg) {
			display: block;
			position: absolute;
			top: 50px;
			left: auto;
			right: -3px;
			width: 100%;
			min-width: 260px;
			max-width: 500px;
			margin-top: -5px;
			padding: 16px;
			background-color: color(bright);
			box-shadow: $box-shadow--1;
			border-radius: $border-radius;
			z-index: 200;

			&::before {
				content: '';
				display: block;
				width: 41px;
				height: 20px;
				position: absolute;
				top: -14px;
				right: 5px;
				@include background-svg('<svg width="41" height="20" xmlns="http://www.w3.org/2000/svg"><defs><filter x="-.5%" y="-1.3%" width="101.1%" height="103%" filterUnits="objectBoundingBox" id="a"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.51372549 0 0 0 0 0.57254902 0 0 0 0 0.607843137 0 0 0 1 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><path d="M850.5 0L867 12h-33z" filter="url(#a)" transform="translate(-830 2)" fill="#FFF" fill-rule="evenodd"/></svg>');
			}
		}
	}
}


.submenu__item {
	list-style-type: none;
	display: block;
	margin-left: 0;
	margin-bottom: 10px;
	position: relative;

	&:last-child a {
		margin-bottom: 0;
	}
}

.submenu__link {
	@include font-family(sansregular);
	display: block;
	font-size: 85%;
	line-height: 1.4;
	padding: 4px 16px;
	color: color(black);

	@include breakpoint(mid) {
		padding: 10px;
	}

	@include breakpoint(lg) {
		text-align: left;
	}

	&:hover {
		color: color(red500);
	}

	&:active {
		color: color(red600);
	}

	&.active {
		color: color(red600);
	}

	span {
		padding: 8px 0;
		// white-space: nowrap;
	}
}




//
// Toogle open / close States
//
.menu__item.is-open {

	.menu__link {
		color: color(red500);
	}

	> .menu__link {
		&::after {
			@include background-svg('<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18 8c.563 0 1 .438 1 1 0 .25-.125.5-.313.688l-5.968 6.03A1.044 1.044 0 0112 16c-.25 0-.531-.094-.719-.281L5.312 9.688C5.125 9.5 5 9.25 5 9c0-.563.438-1 1-1 .281 0 .5.094.719.281L12 13.562l5.281-5.28A.99.99 0 0118 8z" fill="#ec0016" fill-rule="evenodd"/></svg>');
		}
	}

	.submenu {
		display: block;
	}
}
