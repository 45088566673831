//*******************************************************************************
// Blogpost - with Media
//*******************************************************************************


.blogpost--media {
	@include breakpoint(mid) {
		margin-left: 10vw;
		margin-right: 10vw;
	}

	.blogpost__date,
	.blogpost__title,
	.blogpost__content,
	.blogpost__taglist {
		@include breakpoint(mid) {
			margin-left: 5vw;
			margin-right: 5vw;
		}
	}

	.blogpost__title {
		margin-bottom: 48px;

		@include breakpoint(mid) {
			margin-bottom: 4vw;
		}
	}

	.blogpost__mediawrapper {
		margin-bottom: 24px;

		@include breakpoint(mid) {
			margin-bottom: 3vw;
		}

		@include breakpoint(max) {
			margin-bottom: 2vw;
		}
	}
}



//
// Blogpost - Left Alignment
//
.blogpost--media.blogpost--alignleft {
	@include breakpoint(mid) {
		width: 47.5vw;
		margin-left: 0;
		margin-right: 0;
	}

	@include breakpoint(max) {
		width: 45vw;
	}

	.blogpost__date,
	.blogpost__title,
	.blogpost__content,
	.blogpost__taglist {
		@include breakpoint(mid) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}




//
// Blogpost - Right Alignment
//
.blogpost--media.blogpost--alignright {
	@include breakpoint(mid) {
		float: right;
		width: 47.5vw;
		margin-left: 0;
		margin-right: 0;

		.section &,
		.section--full &,
		.section--small & {
			margin-bottom: 5vw;
		}
		.section.spacerbottom--zero   & { margin-bottom: 0; }
		.section.spacerbottom--small  & { margin-bottom: 2.5vw; }
		.section.spacerbottom--medium & { margin-bottom: 5vw; }
		.section.spacerbottom--big    & { margin-bottom: 10vw; }
	}

	@include breakpoint(max) {
		width: 45vw;
	}

	.blogpost__date,
	.blogpost__title,
	.blogpost__content,
	.blogpost__taglist {
		@include breakpoint(mid) {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
