//*******************************************************************************
// HEADER
//*******************************************************************************


.header {
	position: relative;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 auto 8vw;
	padding-top: 4vw;
	padding-left: 5vw;
	padding-right: 5vw;
}



.header__logo {
	display: flex;
	align-items: center;
	height: 44px;
	z-index: 200;

	img {
		width: auto;
		height: 5.5vw;
		max-width: none;
		// max-height: 44px;
		min-height: 24px;
	}
}



// .header__navigation {}

.header__subnavigation {
	background-color: #fbfbfb;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 170px;
	padding-right: 50px;
}
