//*******************************************************************************
// ICONS
//*******************************************************************************


.icon {
	position: relative;

	svg {
		width: initial;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}


.icon--block {
	display: block;
}

.icon--inline-block {
	display: inline-block;
}



.icon--24 {
	width: 24px;
	height: 24px;
}

.icon--32 {
	width: 32px;
	height: 32px;
}

.icon--48 {
	width: 48px;
	height: 48px;
}

