//*******************************************************************************
// Blogpost - with Image-Grid
//*******************************************************************************


.blogpost--imagegrid {

	.blogpost__mediawrapper {
		display: grid;
		grid-template-columns: 35vw 50vw;
		grid-template-rows: 1fr 1fr;
		gap: 5vw;

		grid-column: 1 / -1;
		grid-row: 1 / -1;
		margin-right: 0;
		margin-bottom: 5vw;

		@include breakpoint(mid) {
			grid-template-columns: 4fr 4.5fr;
			gap: 0;
			grid-row-gap: 2vw;

			grid-column: 1 / span 10;
			grid-row: 1 / span 4;
			align-self: end;
			margin-right: 2.5vw;
			margin-bottom: 0;
		}

		@include breakpoint(max) {
			grid-template-columns: 4fr 5fr;
			grid-column: 1 / span 9;
			margin-right: 0;
		}
	}



	.blogpost__mediaitem {
		justify-self: stretch;
		align-self: stretch;
	}

	.blogpost__mediaitem--1 {
		grid-column: 1 / span 1;
		grid-row: 1 / span 1;

		@include breakpoint(mid) {
			margin-right: 2vw;

			.msie & {
				margin-bottom: 1vw;
			}
		}
	}

	.blogpost__mediaitem--2 {
		grid-column: 1 / span 1;
		grid-row: 2 / span 1;

		@include breakpoint(mid) {
			margin-right: 2vw;

			.msie & {
				margin-top: 1vw;
			}
		}
	}

	.blogpost__mediaitem--3 {
		grid-column: 2 / span 1;
		grid-row: 1 / span 2;
	}

	.image__wrapper {
		height: 100%;
	}

	.image__item {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.image__desc {
		margin-top: .5vw;
		margin-bottom: .5vw;
		padding: 0 2vw;
	}
}



//
// Reversed Version
//
.blogpost--imagegrid-reversed {
	.blogpost__date,
	.blogpost__title,
	.blogpost__content,
	.blogpost__taglist {
		@include breakpoint(mid) {
			grid-column: 1 / span 8;
			margin-left: 0;
			margin-right: 2.5vw;
		}

		@include breakpoint(max) {
			grid-column: 1 / span 6;
			margin-right: 0;
		}
	}

	.blogpost__mediawrapper {

		@include breakpoint(mid) {
			grid-column: 9 / span 10;
			margin-left: 2.5vw;
			margin-right: 0;
		}

		@include breakpoint(max) {
			grid-column: 8 / span 9;
			margin-left: 0;
		}
	}
}
