.footer-image-and-text {
  background-color: color(coolgray600);

  @include breakpoint(mid) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__image-wrapper {
	> figure {
		margin-bottom: 0;
		height: 100%;
	}

    img {
      display: block;
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    padding: 24px 5vw;

    @include breakpoint(mid) {
      padding: 5vw 10vw 5vw 5vw
    }

    &>*:last-child {
      margin-bottom: 0;
    }
  }
}

.teaser401__css-missing {
  display: none;
}

$altbg: #002b59;

.footer-image-and-text--alt-background {
  background-color: $altbg;
  background-image: url("data:image/svg+xml,%3Csvg width='880' height='1699' viewBox='0 0 880 1699' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 4C3 4 326.802 195.991 284.595 293.524C230.58 418.345 216.679 527.92 262.221 621.433C331.991 764.902 459.087 770.075 533.888 900.749C608.688 1031.42 538.521 1145.63 568.839 1210.83C618.618 1318.5 847.919 1454.21 855.465 1554.12C863.011 1654.03 788.211 1695 788.211 1695' stroke='url(%23paint0_linear_1588_3868)' stroke-width='20' stroke-miterlimit='10'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1588_3868' x1='-21.4923' y1='851.474' x2='895.58' y2='851.474' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23E5162E'/%3E%3Cstop offset='1' stop-color='%23EF7A1A'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1vw bottom -10px;
  background-size: auto 110%;
}

.footer-image-and-text .image__wrapper {
  margin:0;
}
