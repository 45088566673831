//*******************************************************************************
// VIDEOPLAYER
//*******************************************************************************

.video {
	position: relative;
}

.embed-responsive,
.video__inner {
	background-color: color(coolgray200);
	border-radius: $border-radius;
	display: block;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: 100%;

	&::before {
		display: block;
		content: '';
		padding-bottom: 56.25%;
	}

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
		object-fit: contain;
	}

	video::-webkit-media-controls {
		visibility: hidden;
	}

	video::-webkit-media-controls-enclosure {
		visibility: visible;
	}
}

.video__cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: none;
	width: 100%;
	border: none;
	opacity: .7;
	transition: opacity .3s ease;

	// Special Version for own html5 Videoplayer
	video + & {
		opacity: 1;
	}
}

.video__buttonwrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.1);
	transition: background-color $transition-time $transition-function;

	&:focus,
	&:hover {
		background-color: rgba(0,0,0,.2);
		cursor: pointer;
	}

	&:focus {
		outline: none;
		text-decoration: none;
		box-shadow: inset 0 0 0 3px color(red500);
	}


	&:focus,
	&:hover {
		.video__button,
		.teaser__playvideo {
			outline: none;
			color: #fff;
			// background-color: color(red600);
			// border-color: color(red600);
			text-decoration: none;
		}
	}

}


.video__button {
	position: absolute !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video__infos {
	@extend %media__infos;
}



.video__desc,
.video__meta {
	@extend %media__desc;
}



//
// DB Consent Manager - YouTube
// https://docs.bahn-x.de/consent-layer/integration-guides/consent-overlay/youtube/
//
.video--db-youtube {

	db-youtube {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	.video__cover {
		z-index: 2;
		opacity: 1;
	}

	.video__buttonwrapper {
		z-index: 3;
	}

	consent-overlay {
		z-index: 1;
	}
}
