//*******************************************************************************
// Map Component
//*******************************************************************************

//
//
//
.map {
	// outline: 1px solid rebeccapurple;
	display: flex;
	flex-direction: column-reverse;

	@include breakpoint(mid) {
		display: grid;
		grid-template-columns: repeat(18, 1fr);
		grid-template-rows: auto auto;
	}

	@include breakpoint(max) {
		grid-template-columns: repeat(16, 1fr);
	}
}



//
// Media Item
//
.map__media {
	margin-bottom: 5vw;

	@include breakpoint(mid) {
		grid-row: 1;
		grid-column-start: 1;
		grid-column-end: span 10;
		margin-bottom: 0;
		margin-right: 2.5vw;
	}

	@include breakpoint(max) {
		grid-column-end: span 9;
		margin-right: 0;
	}

	.image__desc,
	.video__infos {
		@include breakpoint(mid) {
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
		}
	}
}



//
// Media Inner Wrapper
//
.map__mediainner {
	// outline: 2px solid #000;
	position: relative;

	> .image--overlay {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;

		transition: opacity 0.2s ease-in-out;

		&.active {
			opacity: 1;
		}
	}
}



//
// Text Column
//
.map__content {
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint(min) {
		margin: 0 calc(15vw + (160 - 80) * ((100vw - 800px) / (1600 - 800)));
	}

	@include breakpoint(mid) {
		grid-row: 1;
		grid-column-start: 11;
		grid-column-end: span 8;
		align-self: end;
		margin-left: 2.5vw;
		margin-right: 0;
	}

	@include breakpoint(max) {
		grid-column-end: span 6;
		margin-left: 0;
	}

	h3 {
		margin-bottom: 0;
		color: color(black);
	}

	span {
		@include textcompactsize;
		margin-bottom: 0;
	}
}



//
// Headline + Text Item Wrapper
//
.map__text {
	display: block;
	position: relative;
	margin-bottom: 2vw;
	padding: .5vw .5vw .5vw 36px;
	border-radius: $border-radius;
	transition: $transition;

	&.hover,
	&:hover {
		background-color: color(white);
		cursor: pointer;
		box-shadow: $box-shadow--1;

		h3 {
			color: color(red500);
		}

		&::before {
			border-color: color(red500);
			color: color(white);
			background-color: color(red500);
		}
	}


	&::before {
		@include font-family(headblack);
		position: absolute;
		top: .8vw;
		left: 5px;
		content: attr(data-number);
		display: block;
		width: 20px;
		height: 20px;
		font-size: 14px;
		line-height: 1.5;
		text-align: center;
		color: color(black);
		background-color: color(white);
		border: 2px solid color(black);
		border-radius: 4px;
		transition: $transition;

		@include breakpoint(mid) {
			width: 24px;
			height: 24px;
			font-size: 16px;
		}
	}
}



//
// Link-Button
//
.linkbutton {
	.map__text.hover &,
	.map__text:focus &,
	.map__text:hover & {
		color: color(red500);

		&::after {
			background-position: left 12px top 2px;
			@include background-svg('<svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4 4-4 4" stroke="#ec0016" stroke-width="1.333" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		}
	}
}



//
// Map Marker Icons
//
.map__marker {
	@include font-family(headblack);
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	font-size: 14px;
	line-height: 1.5;
	text-align: center;
	color: color(black);
	background-color: color(white);
	border: 2px solid color(black);
	border-radius: 4px;
	transition: $transition;
	z-index: 5;

	&.hover,
	&:focus,
	&:hover {
		border-color: color(red500);
		color: color(white);
		background-color: color(red500);
		box-shadow: $box-shadow--1;
	}

	@include breakpoint(mid) {
		width: 24px;
		height: 24px;
		font-size: 16px;
	}
}
