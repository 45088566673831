//*******************************************************************************
// Related Tiles Component
//*******************************************************************************




.newsletterform {
	background-color: color(coolgray100);
	padding: $spacer * 5;

	@include breakpoint(mid) {
		// display: flex;
		padding: ($spacer * 5) ($spacer * 15);
	}

	// @include breakpoint(lg) {
	// 	padding-left: $spacer * 5;
	// 	padding-right: $spacer * 10;
	// }

	.newsletterform__content {
		padding-right: $spacer * 10;
	}

	form {
		margin-left: auto;
		margin-right: auto;

		// @include breakpoint(mid) {
		// 	// flex-direction: row;
		// 	// align-items: flex-end;
		// 	max-width: 90vw;
		// }

		// @include breakpoint(max) {
		// 	max-width: 80vw;
		// }
	}

	fieldset {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
	}


	.form__group {
		flex: 1 1 auto;

		// @include breakpoint(md) {
		// 	display: flex;
		// 	justify-content: space-between;
		// 	align-items: center;
		// }

		// @include breakpoint(mid) {
		// 	flex: 0 1 auto;
		// 	margin-bottom: 0;
		// 	width: 40vw;
		// 	margin-bottom: 2.5vw;
		// 	// margin-right: 5vw;
		// }
	}



	.form__checkbox {
		min-height: 28px;

		@include breakpoint(mid) {
			// width: 70vw;
			// flex: 0 0 auto;
		}
	}

	// .form__submit {
	// 	// flex: 0 1 auto;

	// 	@include breakpoint(mid) {
	// 		margin-bottom: 0;
	// 		// justify-content: flex-end;
	// 		// width: 15vw;
	// 	}
	// }


	.form__label {
		display: block;
		flex: 1 0 15vw;
		margin-right: 10px;
		margin-bottom: 0;

		// @include breakpoint(md) {
		// 	flex: 1 0 7vw;
		// }
	}

	// .form__control {
	// 	// flex: 1 1 auto;

	// 	@include breakpoint(mid) {
	// 		max-width: none;
	// 	}
	// }

	.form__control,
	.form__checkbox-label::before {
		@include placeholder{ color: color(coolgray200); }
		background-color: color(white);
	}

}



