.iframe,
.iframe__wrapper {
	position: relative;
}

.iframe__wrapper {
	background-color: color(warmgray100);
	border-radius: $border-radius;
	height: 0;

	&--format-16-9 {
		padding-bottom: 56.25%;
	}
	&--format-4-3 {
		padding-bottom: 75%;
	}
	&--format-3-4 {
		padding-bottom: 133.33%;
	}
	&--format-9-16 {
		padding-bottom: 177.778%;
	}
}

.iframe__iframe,
.iframe__warning {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.iframe__iframe {
	border: 0;
}

.iframe__warning {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&-text {
		text-align: center;
		max-width: 80%;

		@include breakpoint(mid) {
			max-width: 60%;
		}
	}
}

.iframe__infos {
	@extend %media__infos;
}

.iframe__desc,
.iframe__meta {
	@extend %media__desc;
}
