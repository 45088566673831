//*******************************************************************************
// Download-Tiles
//*******************************************************************************

//
// UL Element
//
.downloadtileslist {
	margin-bottom: 0;
}

.downloadtiles__listitem {
	margin-bottom: 16px !important;

	&:last-child {
		margin-bottom: 0 !important;
	}
}


//
// Link Element
//
.downloadtile {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	position: relative;
	background-color: color(coolgray100);
	color: color(dark);
	padding: 8px 48px 8px 8px;
	border-radius: $border-radius;
	transition: $transition;

	@include breakpoint(mid) {
		padding: 16px 5vw 16px 5vw;
		min-height: 96px;
	}

	&:focus,
	&:hover {
		color: color(red500);
		box-shadow: $box-shadow--1;
		background-color: color(white);
	}

	&::before {
		@include breakpoint(mid) {
			content: '';
			display: block;
			position: absolute;
			left: 2.5vw;
			top: 50%;
			width: 3vw;
			max-width: 40px;
			height: 100%;
			max-height: 60px;
			transform: translate(-50%, -50%);
			background: center no-repeat transparent;
			background-size: 100%;
		}
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		right: 8px;
		height: 32px;
		width: 32px;

		transform: translate(0, -50%);
		border-radius: 50%;
		background: center center no-repeat #fff;
		background-size: 65%;
		@include background-svg('<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M4 21c0-.563.438-1 1-1 .563 0 1 .437 1 1v6c0 .562.438 1 1 1h18c.562 0 1-.438 1-1v-6c0-.563.437-1 1-1 .562 0 1 .437 1 1v6a3 3 0 01-3 3H7a3 3 0 01-3-3v-6zm5.281-5.313a.97.97 0 01-.28-.687c0-.5.343-1 1-1 .25 0 .5.094.718.281L15 18.593V3c0-.563.438-1 1-1 .563 0 1 .438 1 1v15.593l4.281-4.312A1.1 1.1 0 0122 14c.594 0 1 .437 1 1 0 .25-.094.5-.281.719l-6 6a1.1 1.1 0 01-.719.28.97.97 0 01-.688-.28l-6.03-6.032z" fill="#282D37" fill-rule="nonzero"/></svg>');

		@include breakpoint(mid) {
			right: 2.5vw;
			width: 3vw;
			height: 3vw;
			transform: translate(50%, -50%);
		}
	}
}


.downloadtile__name {
	@include font-family(sansbold);
	display: block;
	flex-grow: 1;
	width: 100%;

	@include breakpoint(mid) {
		font-size: 16px;
		line-height: 24px;
	}
}


.downloadtile__filetype,
.downloadtile__filesize,
.downloadtile__date {
	color: color(coolgray500);
	padding-right: 16px;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 2;

	@include breakpoint(mid) {
		padding-right: 2.5vw;
	}
}


// .downloadtile__filetype {}
// .downloadtile__filesize {}
// .downloadtile__date {}






.downloadtile--pdf::before {
	@include background-svg('<svg width="40" height="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M.749.749h16.667v20H.749z"/></defs><g fill="none" fill-rule="evenodd"><path d="M36.667 1.25c.575 0 1.096.233 1.473.61s.61.898.61 1.473h0v53.334c0 .575-.233 1.096-.61 1.473s-.898.61-1.473.61h0H3.333a2.077 2.077 0 01-1.473-.61 2.077 2.077 0 01-.61-1.473h0v-43.53L13.136 1.25z" stroke="#000" stroke-width="2.5"/><rect fill="#000" x="6.667" y="38.333" width="27.5" height="2.5" rx="1.25"/><rect fill="#000" x="6.667" y="48.333" width="9.167" height="2.5" rx="1.25"/><rect fill="#000" x="25" y="15" width="9.167" height="2.5" rx="1.25"/><rect fill="#000" x="6.667" y="31.667" width="27.5" height="2.5" rx="1.25"/><rect fill="#000" x="6.667" y="25" width="27.5" height="2.5" rx="1.25"/><g transform="translate(.084 .085)"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path d="M14.916-6.751v18.333a3.333 3.333 0 01-3.334 3.333H-6.75a3.335 3.335 0 01-3.228-2.5h20.311c1.15 0 2.084-.932 2.084-2.083V-9.98a3.335 3.335 0 012.5 3.229z" fill="#000" mask="url(#b)"/></g></g></svg>');
}

.downloadtile--image::before,
.downloadtile--png::before,
.downloadtile--jpg::before,
.downloadtile--jpeg::before {
	@include background-svg('<svg width="40" height="60" xmlns="http://www.w3.org/2000/svg"><g stroke="#000" stroke-width="2.5" fill="none" fill-rule="evenodd"><rect x="1.25" y="1.25" width="37.5" height="57.5" rx="3.333"/><circle cx="23.333" cy="26.667" r="8.75"/><path d="M8.883 52.083h12.37L15.068 41.25zM7.917 7.917h7.5v7.5h-7.5z"/></g></svg>');
}

.downloadtile--zip::before {
	@include background-svg('<svg width="40" height="60" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect x="1.25" y="1.25" width="37.5" height="57.5" rx="3.333" stroke="#000" stroke-width="2.5"/><g transform="rotate(-90 24.167 9.167)" fill="#000"><rect transform="rotate(90 7.083 4.583)" x="2.5" y="3.333" width="9.167" height="2.5" rx="1.25"/><rect transform="rotate(90 13.75 4.583)" x="9.167" y="3.333" width="9.167" height="2.5" rx="1.25"/><rect transform="rotate(90 20.417 4.583)" x="15.833" y="3.333" width="9.167" height="2.5" rx="1.25"/><rect transform="rotate(90 26.25 4.583)" x="21.667" y="3.333" width="9.167" height="2.5" rx="1.25"/><rect y="3.333" width="32.5" height="2.5" rx="1.25"/></g><rect stroke="#000" stroke-width="2.5" transform="rotate(90 19.583 39.583)" x="12.5" y="36.25" width="14.167" height="6.667" rx="1.25"/></g></svg>');
}
